<template>
  <div class="header section">
    <h1>Alba</h1>
    <h4>Character Sheet</h4>
  </div>
</template>

<script>
export default {
  name: 'Header'
}
</script>
